<template>
  <LevelsTable
    :levels="levels"
    :tests="tests"
    :loading="loading"
  >
    <template #topActions>
      <VBtn
        color="primary"
        :to="{
          name : Names.R_MULTI_LEVEL_TEST_TESTS_LEVELS_CREATE
        }"
      >
        <VIcon left>
          fal fa-plus
        </VIcon>

        Новый уровень
      </VBtn>

      <VDialog
        v-model="confirmDialog"
        max-width="500px"
        @click:outside="handleCancel"
      >
        <VCard>
          <VCardTitle class="headline">
            Удалить уровень
          </VCardTitle>

          <VCardText>
            Уровень <span class="red--text">{{ level.name }}</span> будет удален! <br>
            Действительно хотите удалить уровень?
          </VCardText>

          <VCardActions>
            <VSpacer />

            <VBtn
              text
              @click="handleCancel"
            >
              Нет
            </VBtn>

            <VBtn
              color="red darken-1"
              text
              @click="handleDelete"
            >
              Да
            </VBtn>
          </VCardActions>
        </VCard>
      </VDialog>
    </template>

    <template #rowActions="{ item }">
      <VTooltip left>
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            small
            color="blue"
            class="mx-1"
            :to="{
              name : Names.R_MULTI_LEVEL_TEST_TESTS_LEVELS_VIEW,
              params : {
                testId : testId,
                levelId : item.id
              }
            }"
            v-bind="attrs"
            v-on="on"
          >
            <VIcon small>
              fal fa-eye
            </VIcon>
          </VBtn>
        </template>

        <span>Просмотр</span>
      </VTooltip>

      <VTooltip left>
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            small
            color="blue"
            class="mx-1"
            :to="{
              name : Names.R_MULTI_LEVEL_TEST_TESTS_LEVELS_QUESTIONS_INDEX,
              params : {
                testId : testId,
                levelId : item.id
              }
            }"
            v-bind="attrs"
            v-on="on"
          >
            <VIcon small>
              fal fa-list
            </VIcon>
          </VBtn>
        </template>

        <span>Вопросы</span>
      </VTooltip>

      <VTooltip left>
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            small
            color="orange"
            class="mx-1"
            :to="{
              name : Names.R_MULTI_LEVEL_TEST_TESTS_LEVELS_EDIT,
              params : {
                testId : testId,
                levelId : item.id
              }
            }"
            v-bind="attrs"
            v-on="on"
          >
            <VIcon small>
              fal fa-edit
            </VIcon>
          </VBtn>
        </template>

        <span>Редактирование</span>
      </VTooltip>

      <VTooltip left>
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            small
            color="red"
            class="mx-1"
            v-bind="attrs"
            v-on="on"
            @click.stop="deleteLevel(item)"
          >
            <VIcon small>
              fal fa-trash
            </VIcon>
          </VBtn>
        </template>

        <span>Удаление</span>
      </VTooltip>
    </template>
  </LevelsTable>
</template>

<script>
import LevelsTable from './LevelsTable.vue';

export default {
  name: 'LevelsTableContainer',

  components: {
    LevelsTable,
  },

  inject: ['Names'],

  data() {
    return {
      tests: [],
      levels: [],
      loading: false,
      level: {},
      confirmDialog: false,
    };
  },

  computed: {
    testId() {
      return this.$route.params.testId;
    },
    topicId() {
      return this.$route.params.topicId;
    },
  },
  async created() {
    await this.fetch();
  },

  methods: {
    async fetch() {
      try {
        this.loading = true;

        const { topicId } = this;
        const data = { topicId };

        const { tests } = await this.$di.api.MultiLevelTest.TestsIndex();
        const { levels } = await this.$di.api.MultiLevelTest.LevelsByTopic({ data });

        this.tests = tests;
        this.levels = levels;
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    async handleDelete() {
      try {
        this.loading = true;

        const { id } = this.level;
        const data = { id };

        await this.$di.api.MultiLevelTest.LevelsDelete({ data });

        this.resetLevelEdit();
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
        await this.fetch();
      }
    },

    resetLevelEdit() {
      this.confirmDialog = false;
      this.level = {};
    },

    handleCancel() {
      this.resetLevelEdit();
    },

    deleteLevel(item) {
      this.confirmDialog = true;
      this.level = { ...item };
    },
  },
};
</script>
