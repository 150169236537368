<template>
  <VDataTable
    class="elevation-1"
    :headers="headers"
    :items="levels"
    :loading="loading"
  >
    <template #top>
      <VToolbar flat>
        <VToolbarTitle>Уровни</VToolbarTitle>

        <VSpacer />

        <slot name="topActions" />
      </VToolbar>
    </template>

    <template #item.testId="{ item }">
      <div class="ma-2">
        <div class="mb-1">
          {{ testName(item) }}
        </div>

        <div>
          <VChip small>
            {{ item.testId }}
          </VChip>
        </div>
      </div>
    </template>

    <template #item.actions="{ item }">
      <slot
        name="rowActions"
        :item="item"
      />
    </template>
  </VDataTable>
</template>

<script>
export default {
  name: 'LevelsTable',

  props: {
    levels: {
      type: Array,
      required: true,
    },

    tests: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      headers: [
        {
          text: 'Название',
          align: 'left',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Порог',
          align: 'left',
          sortable: true,
          value: 'threshold',
        },
        {
          text: 'Значение',
          align: 'left',
          sortable: true,
          value: 'value',
        },
        {
          text: '',
          align: 'right',
          sortable: false,
          value: 'actions',
        },
      ],
    };
  },

  methods: {
    testName(item) {
      const test = this.tests.find((c) => c.id === item.testId);

      if (test) return test.name;

      return '';
    },
  },
};
</script>
