<template>
  <TTView>
    <VRow>
      <VCol>
        <LevelsTableContainer />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import LevelsTableContainer from '../../../components/multi-level-test/levels/LevelsTableContainer.vue';

export default {
  name: 'TestsLevels',

  components: {
    LevelsTableContainer,
  },
};
</script>
